<template>
	<div class="container-fluid contain">
		<div style="display: flex; flex-direction: row; align-items: center">
			<img src="@/assets/images/icon_arrow_left.png" style="width: 18px" @click="$router.go(-1)"/>
			<div style="margin-left: 10px;">
				<div class="title" style="margin-left: 10px">Order #{{order.id.substr(order.id.length - 6).toUpperCase()}}</div>
				<div class="description">Deliver on {{moment(state.order.orders &&
					state.order.orders[$route.query.id].deliverBy).format('MMMM DD, YYYY')}}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-9 col-mg-12">
				<div class="item-cart row" v-for="(item, index) in items" :key="index">
					<div class="item-body col-sm-12 col-md-8">
						<img :src="get(item, 'product.images[0].url')" style="width: 15%; height: 60px; object-fit: contain">
						<div style="margin-left: 40px">
							<span class="item-title" style="padding: 0;">{{item && item.product && item.product.name}}</span><br/>
							<div class="deliver-text" style="font-weight: 500; color: #A0A0A0; font-size: 13px;">
								{{getIngredients(item)}}
							</div>
						</div>
					</div>
					<div class="price-view col-sm-12 col-md-4">
						<div style="flex-direction: row; justify-content: space-between; margin-left: 35px;">
							<span style="font-weight: bold; font-size: 22px;"
										v-if="item.product.pricing[0].retailPrice">${{item.product.pricing[0].retailPrice}}</span>
						</div>
					</div>
				</div>
				<div class="desc-view" style="margin-top: 18px;">
					<span class="desc-text text-right">Subtotal</span>
					<span class="desc-text text-right" style="width: 70px;">{{get(cart, 'subtotal', 0).toFixed(2)}}</span>
				</div>
				<div class="desc-view">
					<span class="desc-text text-right">Tax</span>
					<span class="desc-text text-right" style="width: 70px;">{{get(cart, 'tax', 0).toFixed(2)}}</span>
				</div>
				<div class="desc-view">
					<span class="desc-text text-right">Tip</span>
					<span class="desc-text text-right" style="width: 70px;">{{get(cart, 'tip', 0).toFixed(2)}}</span>
				</div>
				<!--						<div class="desc-view">-->
				<!--							<span class="desc-text text-right">Service Charge</span>-->
				<!--							<span class="desc-text text-right" style="width: 70px;">{{get(cart, 'serviceFee', 0).toFixed(2)}}</span>-->
				<!--						</div>-->
				<!--						<div class="desc-view">-->
				<!--							<span class="desc-text text-right">Flute Wallet</span>-->
				<!--							<span class="desc-text text-right" style="width: 70px;">{{get(cart, 'wallet', 0).toFixed(2)}}</span>-->
				<!--						</div>-->
				<!--						<div class="desc-view">-->
				<!--							<span class="desc-text text-right">Total Due Now</span>-->
				<!--							<span class="desc-text text-right" style="width: 70px;">{{get(cart, 'dueNow', 0).toFixed(2)}}</span>-->
				<!--						</div>-->
				<!--						<div class="desc-view">-->
				<!--							<span class="desc-text text-right" style="color: #00bf00">Instant Rebates (Wallet)</span>-->
				<!--							<span class="desc-text text-right" style="width: 70px;color: #00bf00">{{get(cart, 'rebates', 0).toFixed(2)}}</span>-->
				<!--						</div>-->
				<div class="desc-view">
					<span class="desc-text text-right font-weight-bold">Grand Total</span>
					<span class="desc-text text-right font-weight-bold" style="width: 70px;">
						${{get(cart, 'total', 0).toFixed(2)}}
					</span>
				</div>
			</div>
			<div class="col-lg-3 col-mg-12" style="margin-top: 21px;">
				<div class="address-view">
					<div class="address-header">DELIVERY ADDRESS</div>
					<div class="address-content mt-3 mb-3">
						<div style="margin-left: 10px; width: 70%">
							<div class="address-text">
								{{get(order, 'deliverTo.name', '')}}
							</div>
							<div class="address-text font-weight-light">
								{{`${get(order, 'deliverTo.address', '')} ${get(order, 'deliverTo.address2', '') ? `Unit #
								${get(order,'deliverTo.address2', '')},` : ''}
								${get(order, 'deliverTo.city', '')}, ${get(order, 'deliverTo.state', '')} ${get(order,
								'deliverTo.postalCode', '')}`}}
							</div>
							<div class="address-text font-weight-light">
								{{`${get(order, 'deliverTo.phones[0].number', '')}`}}
							</div>
						</div>
						<!--                        <button class="btn edit">EDIT</button>-->
					</div>
				</div>
				<div class="address-view">
					<div class="address-header">PAYMENT METHOD</div>
					<div class="address-content mt-3 mb-3">
						<img src="@/assets/images/icon_apple.png" style="width: 30px; margin-left: 10px;">
						<div class="address-text ml-1">Paid with Apple Pay</div>
					</div>
				</div>
				<div class="delivery-notes-view">DELIVERY NOTES</div>
				<div style="border: 1px solid #d6d6d6; box-sizing: border-box; border-radius: 5px; width: 100%; font-size: 14px; padding: 5px; height: 100px;" >{{get(order, 'notes')}}</div>
			</div>
		</div>
	</div>
</template>

<script>
  import {reactive, toRefs} from "@vue/composition-api";
  import {json} from 'overmind';
  import moment from 'moment';
  import {get} from 'lodash';

  export default {
    setup() {
      const data = reactive({
        order: {},
        cart: {},
        items: [],
        deliverBy: false,
        moment,
        get,
      });
      const getIngredients = (item) => {
        const ingredients = [];
        if (item.product?.tasks?.length > 0) {
          item.product.tasks.map(o => {
            if (o.inventoryProduct && o.inventoryProduct.ingredient && item.modifiers.findIndex(m => m.id === o.id) > 0)
              ingredients.push('No ' + o?.inventoryProduct?.ingredient?.name);
          })
        }
        return ingredients.join(', ');
      };
      return {
        getIngredients,
        ...toRefs(data)
      }
    },
    beforeCreate() {
      if (!this.state.currentUser.id) {
        this.$router.push({path: '/', query: this.$route.query?.event === 'hush' ? {event: 'hush'}: {}})
      }
    },
    beforeMount() {
      this.order = json(this.state.order.orders && this.state.order.orders[this.$route.query.id]);
      if (this.order) {
        this.cart = this.order.cart;
        this.items = this.cart.items;
        this.deliverBy = moment(this.order.deliverBy).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
      }
    },
    beforeUpdate() {
      this.order = json(this.state.order.orders && this.state.order.orders[this.$route.query.id]);
      if (this.order) {
        this.cart = this.order.cart;
        this.items = this.cart.items;
        this.deliverBy = moment(this.order.deliverBy).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
      }
    }
  }
</script>

<style lang="css" scoped>
	.delivery-notes-view {
		font-size: 11px;
		line-height: 23px;
		font-weight: bold;
		margin-top: 10px;
	}
	
	.item-cart {
		display: flex;
		flex-direction: row;
		padding: 22px 26px;
		justify-content: space-between;
		background-color: #fbfbfb;
		border: 1px solid #cacaca;
		border-radius: 8px;
		box-sizing: border-box;
		margin-top: 26px;
	}
	
	.item-body {
		display: flex;
		flex-direction: row;
	}
	
	.price-view {
		display: flex;
		flex-direction: row;
		width: 350px;
		justify-content: flex-end;
	}
	
	.desc-view {
		display: flex;
		justify-content: flex-end;
		flex-direction: row;
		margin-bottom: 2px;
	}
	
	.desc-text {
		font-size: 16px;
		line-height: 20px;
		font-weight: 300;
		color: black;
	}
	
	.service {
		height: 32px;
		background-color: black;
		border-radius: 3px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0px 15px;
		margin-top: 26px;
	}
	
	.address-view {
		border: 1px solid #d1d1d1;
		box-sizing: border-box;
		border-radius: 3px;
		margin-top: 5px;
	}
	
	.address-header {
		display: flex;
		align-self: stretch;
		height: 30px;
		background-color: #efefef;
		align-items: center;
		padding: 0px 11px;
		font-size: 11px;
		color: black;
		font-weight: bold;
	}
	
	.address-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 12px;
		margin-top: 5px;
	}
	
	.address-text {
		font-weight: 600;
		font-size: 10px;
		line-height: 13px;
		color: black;
	}
	
	.description {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		margin-left: 12px;
	}
</style>
